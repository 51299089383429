import React from "react"
import { graphql } from "gatsby"

import { FadeInTop } from "../components"
import Layout from "../components/layout"
import Seo from "../components/seo"
import BlogArticles from "../components/BlogArticles"
import TeamSmiles from "../components/TeamSmiles"
import Subscribe from "../components/Subscribe"

const BlogCategory = ({ data, pageContext }) => {
  const SEOData = {
    title: data.Category.title,
    description: data.Category.description,
  }
  return (
    <Layout>
      <Seo
        title={`${SEOData.title} articles - Blog | Braudit`}
        description={SEOData.description}
      />
      <IntroSection category={data.Category} />
      <BlogArticles articles={data.CategoryArticles.nodes} />
      {/* nice spacer below */}
      <div className="pt-12 md:pt-14 lg:pt-0"></div>
      <TeamSmiles />
      <SubscribeSection />
    </Layout>
  )
}

const IntroSection = ({ category: { title, description } }) => {
  return (
    <section className="c-container-full lg:space-x-20 xl:space-x-40 pt-24 lg:pt-40 lg:flex justify-between">
      <FadeInTop>
        <h1 className="font-headings text-5xl lg:text-6xl xl:text-8xl leading-tight lg:leading-tight xl:leading-tight mb-4">
          {title}
        </h1>
      </FadeInTop>
      <FadeInTop delay={0.2}>
        <p className="flex-shrink-0 text-[#999] text-lg lg:text-xl leading-normal lg:leading-normal xl:pr-20 lg:pt-4 xl:pt-6">
          {description}
        </p>
      </FadeInTop>
    </section>
  )
}

const SubscribeSection = () => {
  return (
    <div className="bg-white pt-20 lg:pt-28 xl:pt-40">
      <Subscribe lightVariant={true} />
    </div>
  )
}

export default BlogCategory

export const query = graphql`
  query ($id: String!) {
    CategoryArticles: allSanityPost(
      sort: { fields: publishedAt, order: DESC }
      filter: {
        publishedAt: { ne: null }
        category: { elemMatch: { _id: { eq: $id } } }
      }
    ) {
      nodes {
        title
        category {
          title
          slug {
            current
          }
        }
        slug {
          current
        }
        mainImage {
          asset {
            gatsbyImageData
          }
        }
        publishedAt(formatString: "D MMMM YYYY")
      }
    }

    Category: sanityCategory(_id: { eq: $id }) {
      title
      description
    }

    SEOData: sanityPageBlog {
      SEO {
        title: seoTitle
        description: seoDescription
      }
    }

    PageData: sanityPageBlog {
      title
    }
  }
`
