import React from "react"
import { Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import slugify from "slugify"

import { FadeInTop } from "./animations"
import BlogArticleCard from "./BlogArticleCard"

const BlogArticles = ({ articles }) => {
  return (
    <section className="px-4 sm:px-0 sm:w-11/12 mx-auto">
      <h2 className="sr-only">All articles</h2>
      <div className="sm:flex flex-wrap sm:-mx-4 pt-8 sm:pt-16 2xl:pt-24">
        {articles.map((article, i) => {
          return (
            <FadeInTop delay={(i % 3) * 0.25} threshold={0.15}>
              <BlogArticleCard data={article} />
            </FadeInTop>
          )
        })}
      </div>
    </section>
  )
}

export default BlogArticles
